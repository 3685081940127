<template>
  <div>
    <PageHeader :items="items" />

    <div class="card">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-md-6">
            <TextInput
              v-model="form.couponNumber"
              type="text"
              :label="$t('Coupon Code')"
              :tooltipMessage="$t('Coupon Code')"
              :required="true"
              :key="form.couponNumber"
              :disabled="true"
            />
          </div>
          <div class="col-md-6">
            <TextInput
              v-model="form.dueDate"
              type="date"
              :label="$t('Due Date')"
              :tooltipMessage="$t('Due Date')"
              :required="true"
              :key="form.dueDate"
              :disabled="true"
            />
            
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6">
            <TextInput
              v-model="form.freeUsers"
              type="number"
              :label="$t('Free Coins')"
              :required="true"
              :disabled="true"
            />
            
          </div>
          <div class="col-md-6">
            <TextInput
              v-model="form.createdUser"
              type="text"
              :label="$t('Creator')"
              :required="true"
              :disabled="true"
            />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-6">
            <TextInput
              v-model="form.usedBy"
              type="text"
              :label="$t('Used By')"
              :disabled="true"
            />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-12">
            <label class="input-label form-label" for="cars">{{ $t("Notes") }}</label>
            <textarea
              disabled
              class="form-control"
              rows="5"
              v-model="form.notes"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Coupon Codes"),
          to: "/coupon-codes",
        },
        {
          text: this.$t("Show"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      form: {
        couponNumber: "",
        dueDate: "",
        freeUsers: "",
        createdBy: "",
        createdUser: "",
        notes: "",
        usedBy: ""
      },
    };
  },
  async mounted() {
    await this.$store
      .dispatch("coupons/show", this.$route.params.id)
      .then(async (res) => {
        var response = res?.data?.data;
        this.form.couponNumber = response?.couponNumber;
        this.form.dueDate = response?.dueDate;
        this.form.freeUsers = response?.freeUsers;
        this.form.createdBy = response?.createdBy;
        this.form.notes = response?.notes;
        let responseUser = await this.$store.dispatch("users/show", {
          id: this.form.createdBy,
        });
        this.form.createdUser =
          responseUser?.data?.first_name + " " + responseUser?.data?.last_name;
      });
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
